.popUp-enter{
  z-index: 8;
  transform: translateY(100%);
}
.popUp-enter-active{
  transform: translateY(0);
  transition: transform 250ms;
}

.popUp-exit{
  z-index: 8;
  transform: translateY(0);
}
.popUp-exit-active{
  transform: translateY(100%);
  transition: transform 250ms;
}

.popUp{
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  bottom:0;
}






.fade{
  z-index: 8;
}
.fade-enter{
  opacity: 0;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active{
  opacity: 0;
  transition: opacity 250ms;
}
.fade-enter-active{
  opacity: 1;
  transition: opacity 250ms;
}
